import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import LogoDark from "./utitilies/LogoDark";
import Loader from "./utitilies/Loader";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const Header = () => {

    // const { pathname } = useLocation();
    // const [activePage, setActivePage] = useState();
  
    // useLayoutEffect(() => {
    //     setActivePage(pathname)
    // }, [pathname])

    // GSAP If all content loaded condition true ======================================

    const headerRef = useRef(null);
    const [isMounted, setIsMounted] = useState(true);

    useGSAP(() => {
        const header = headerRef.current;
        if (!header) return;
    
        ScrollTrigger.matchMedia({
            "(min-width: 1281px)": function() {
                gsap.to(header, {
                    scrollTrigger: {
                        trigger: header,
                        start: "top top",
                        end: "bottom top",
                        onEnter: () => header.classList.add('_nav_is-scrolled'),
                        onLeaveBack: () => header.classList.remove('_nav_is-scrolled'),
                    }
                });
            }
        });
    }, []);

    // Use your custom GSAP hook
    useGSAP(() => {
        // GSAP animation to hide the loader
        gsap.to('.loader', {
            opacity: 0,
            duration: 1,
            ease: 'power2.out',
            onComplete: () => setIsMounted(false)
        });
    }, [isMounted]); // Only trigger when loading is true

    useEffect(() => {
        
        const handlePageLoad = () => {
            setIsMounted(true);
          // Add any logic here that happens when the page is fully loaded
        };
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          handlePageLoad();
        } else {
          window.addEventListener('load', handlePageLoad);
        }
    
        // Cleanup the event listener
        return () => window.removeEventListener('load', handlePageLoad);
    }, []);

    if (isMounted) return <Loader/>;
    // ====================================================================================

    return (
        <>
            <header className="_nav" >
                <div className="_container _nav_wrapper" ref={headerRef}>
                    <div className="_nav_menu_brand">
                        <Link to={"/"} className="_nav_menu_brand_link">
                            <LogoDark csName="_nav_menu_brand_media"/>
                        </Link>
                    </div>
                    {/* <div className="_divider_y"></div> */}
                    <div className="_nav_menu_items">
                        <div className="_nav_menu_1">
                            {/* <NavLink
                                to="/our-mission"
                                className={({ isActive }) =>
                                    `${isMounted ? '_nav_menu_item' : ''} ${isActive ? '_nav_menu_item_is-selected' : ''}`
                                }
                            >
                                Mission
                            </NavLink>

                            <NavLink
                                to="/our-services"
                                className={({ isActive }) =>
                                    `${isMounted ? '_nav_menu_item' : ''} ${isActive ? '_nav_menu_item_is-selected' : ''}`
                                }
                            >
                                Our Services
                            </NavLink>

                            <NavLink
                                to="/about-us"
                                className={({ isActive }) =>
                                    `${isMounted ? '_nav_menu_item' : ''} ${isActive ? '_nav_menu_item_is-selected' : ''}`
                                }
                            >
                                About Us
                            </NavLink> */}
                        </div>

                        {/* <div className="_nav_menu_2">
                            <NavLink to="/contact-us" className="_button _is_primary">
                                Contact Us
                            </NavLink>
                        </div> */}
                    </div>
                </div>
            </header>
        </>
    );
}
 
export default Header;