import Header from "./components/Header";
import LogoDark from "./components/utitilies/LogoDark";

function App() {
  return (
    <>
      <Header/>
      <main className="_body_brand_media_wrapper">
        <LogoDark csName={'_body_brand_media'}/>
      </main>
    </>
  );
}

export default App;
