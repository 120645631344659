import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import LogoDark from './LogoDark';


const Loader = () => {
  const svgRef = useRef(null);
  const headingRef = useRef(null);
  const loaderWrapRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;
    const heading = headingRef.current;
    const loaderWrap = loaderWrapRef.current;

    if (!svg || !heading || !loaderWrap) return;

    const tl = gsap.timeline({
      onComplete: () => {
        document.body.style.overflow = 'visible';
      },
    });

    const curve = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
    const flat = 'M0 2S175 1 500 1s500 1 500 1V0H0Z';

    tl.from(heading, {
      delay: 0.5,
      y: 200,
    }).to(heading, {
      delay: 0.5,
      y: -200,
    });

    tl.to(svg, {
      duration: 0.8,
      attr: { d: curve },
      ease: 'power2.easeIn',
    }).to(svg, {
      duration: 0.8,
      attr: { d: flat },
      ease: 'power2.easeOut',
    });

    tl.to(loaderWrap, {
      y: -1500,
    });

    tl.to(loaderWrap, {
      zIndex: -1,
      display: 'none',
    });

    // tl.from(
    //   container,
    //   {
    //     y: 100,
    //     opacity: 0,
    //   },
    //   '-=1.5'
    // );
  }, []);

  return (
    <div>
      <div className="_loader_wrap" ref={loaderWrapRef}>
        <svg viewBox="0 0 1000 1000" preserveAspectRatio="none">
          <path
            id="svg"
            ref={svgRef}
            d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"
          ></path>
        </svg>
        <div className="_loader_wrap-logo">
          <span>
            <h1 ref={headingRef}><LogoDark csName={'_loader_brand_media'}/></h1>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
